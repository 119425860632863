@tailwind base;
@tailwind components;
@tailwind utilities;

/* App.css */
* {
  box-sizing: border-box;
}

html,
body {

  /* Khi body html không cập nhật đúng height có thể dùng CSS sau */
  min-height: 100%;
  height: auto;
  -webkit-overflow-scrolling: touch;

  background-color: #0d0d0d;
  background-attachment: scroll;
  font-family: "Bokman";
  cursor: none; /* ẩn chuột */

}

button, a, input, textarea {
  cursor: none;
}

.scroll-container {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}


.app {
  width: 100%;
  min-height: 100vh;
  /* Full height of the viewport */
  display: flex;
  flex-direction: column;
  /* Stacks children vertically */
  overflow: hidden;
  /* scale: 75%; */
}

.app-body {
  display: flex;
  flex-grow: 1;
  /* Takes up all available space */
}
