/* Home */

#thanh_trang {
  width: 50%;
  right: 0;
  top: 34%;
  height: 7px;
  min-height: 7px;
  border-radius: 2px;
  margin-right: 2%;
  margin-bottom: 50px;
  align-self: flex-end;
  transition: all 1.5s;
  background-color: #fff;
  z-index: 10;
  -webkit-box-shadow: -2px 5px 5px 0 #ffffff,
    -2px 5px 5px 0 rgba(255, 255, 255, 0.75),
    -5px 10px 10px 0 rgba(255, 255, 255, 0.75),
    -15px 15px 15px 0 rgba(255, 255, 255, 0.6),
    -20px 20px 20px 0 rgba(255, 255, 255, 0.6),
    -30px 30px 30px 0 rgba(255, 255, 255, 0.6),
    -40px 40px 40px 0 rgba(255, 255, 255, 0.35);
  box-shadow: -2px 5px 5px 0 #fff, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgb(255 255 255 / 75%),
    -15px 15px 15px 0 rgba(0, 0, 0, 0.6), -20px 20px 20px 0 rgba(0, 0, 0, 0.6),
    -30px 30px 30px 0 rgba(0, 0, 0, 0.6), -40px 40px 40px 0 rgba(0, 0, 0, 0.35);
}

#thanh_den {
  width: 50%;
  right: 0;
  top: 34%;
  height: 7px;
  min-height: 7px;
  border-radius: 2px;
  margin-right: 2%;
  margin-bottom: 50px;
  align-self: flex-end;
  transition: all 1.5s;
  background-color: #000;
  z-index: 10;
  -webkit-box-shadow: -2px 5px 5px 0 #000, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgba(0, 0, 0, 0.75), -15px 15px 15px 0 rgba(0, 0, 0, 0.6),
    -20px 20px 20px 0 rgba(0, 0, 0, 0.6), -30px 30px 30px 0 rgba(0, 0, 0, 0.6),
    -40px 40px 40px 0 rgba(0, 0, 0, 0.35);
  box-shadow: -2px 5px 5px 0 #000, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgba(0, 0, 0, 0.75), -15px 15px 15px 0 rgba(0, 0, 0, 0.6),
    -20px 20px 20px 0 rgba(0, 0, 0, 0.6), -30px 30px 30px 0 rgba(0, 0, 0, 0.6),
    -40px 40px 40px 0 rgba(0, 0, 0, 0.35); /* Thuộc tính chuẩn */
}

.heading-main {
  font-size: clamp(30px, 13.5vw, 184px);
  margin: 0;
  align-self: flex-end;
  /* font-family: 'Heebo'; */
  font-weight: 900;
  top: 10%;
  left: 20%;
  letter-spacing: 0.09em;
  line-height: 1;
}

/* Logo ẩn */
.logo-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
}

.logo-container img {
  width: 20%;
  position: absolute;
  opacity: 0.1;
  transform: scale(0.3) rotate(10deg);
}

.logo-container img:nth-of-type(1) {
  top: 10%;
  left: 60%;
  transform: scale(0.6) rotate(10deg);
}

.logo-container img:nth-of-type(2) {
  top: 80%;
  left: 40%;
}

.logo-container img:nth-of-type(3) {
  top: 5%;
  left: 5%;
}

.logo-container img:nth-of-type(4) {
  top: 70%;
  left: 5%;
}

.logo-container img:nth-of-type(5) {
  top: 20%;
  left: 15%;
  transform: scale(0.7) rotate(10deg);
}

.logo-container img:nth-of-type(6) {
  top: 40%;
  left: 80%;
}

.logo-container img:nth-of-type(7) {
  top: 63%;
  left: 67%;
  transform: scale(0.8) rotate(10deg);
}

.logo-container img:nth-of-type(8) {
  top: 7%;
  left: 73%;
}

.logo-container img:nth-of-type(9) {
  top: 16%;
  left: 80%;
}

.logo-container img:nth-of-type(10) {
  top: 53%;
  left: 44%;
}

/* Icon scroll //////////////////////////////////////////////// */
@import url(https://fonts.googleapis.com/css?family=Cabin);

.icon-scroll {
  position: absolute;
  bottom: 0;
  right: 50%;
  left: 50%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(0.5);
}

.scroll {
  /* margin-top: 50px; */
  padding-top: 45px;
  font-family: "Cabin", sans-serif;
  letter-spacing: 12px;
  text-indent: 12px;
  color: #ffffff;
  animation: colorText 5s ease-out infinite, nudgeText 5s ease-out infinite;
}

.mouse {
  background: #4e5559
    linear-gradient(transparent 0%, transparent 50%, #ffffff 50%, #ffffff 100%);
  position: relative;
  width: 52px;
  height: 88px;
  border-radius: 100px;
  background-size: 100% 200%;
  animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
}

.mouse:before,
.mouse:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.mouse:before {
  width: 46px;
  height: 82px;
  background-color: #222a30;
  border-radius: 100px;
}

.mouse:after {
  background-color: #ffffff;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  animation: trackBallSlide 5s linear infinite;
}

@keyframes colorSlide {
  0% {
    background-position: 0% 100%;
  }

  20% {
    background-position: 0% 0%;
  }

  21% {
    background-color: #4e5559;
  }

  29.99% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }

  30% {
    background-color: #4e5559;
    background-position: 0% 100%;
  }

  50% {
    background-position: 0% 0%;
  }

  51% {
    background-color: #4e5559;
  }

  59% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }

  60% {
    background-color: #4e5559;
    background-position: 0% 100%;
  }

  80% {
    background-position: 0% 0%;
  }

  81% {
    background-color: #4e5559;
  }

  90%,
  100% {
    background-color: #ffffff;
  }
}

@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }

  6% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }

  14% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }

  15%,
  19% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }

  28%,
  29.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }

  30% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }

  36% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }

  44% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }

  45%,
  49% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }

  58%,
  59.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }

  60% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }

  66% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }

  74% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }

  75%,
  79% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }

  88%,
  100% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
}

@keyframes nudgeMouse {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(8px);
  }

  30% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(8px);
  }

  60% {
    transform: translateY(0);
  }

  80% {
    transform: translateY(8px);
  }

  90% {
    transform: translateY(0);
  }
}

@keyframes nudgeText {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(2px);
  }

  30% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(2px);
  }

  60% {
    transform: translateY(0);
  }

  80% {
    transform: translateY(2px);
  }

  90% {
    transform: translateY(0);
  }
}

@keyframes colorText {
  21% {
    color: #4e5559;
  }

  30% {
    color: #ffffff;
  }

  51% {
    color: #4e5559;
  }

  60% {
    color: #ffffff;
  }

  81% {
    color: #4e5559;
  }

  90% {
    color: #ffffff;
  }
}

/* css new */
/* cụm text */
.title {
  font-size: max(2vw, 24px);
}

.text {
  font-size: max(1vw, 15px);
  padding-bottom: 20px;
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.1); /* Một nền trắng mờ */
  backdrop-filter: blur(
    5px
  ); /* Làm mờ nền đằng sau để tập trung vào nội dung */
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.background_content {
  height: 100%;
  width: 100%;
  opacity: 0;
  /* background: rgba(37, 34, 34, 0.7); */
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px); /* Làm mờ nền đằng sau */
  position: fixed;
  /* z-index: 2; */
}

.bg-hong, .ao-thun, .signature, .des-text {
  will-change: transform, opacity;
}

