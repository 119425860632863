@font-face {
  font-family: "Timberline";
  src: url("/public/font/Timberline-Regular.woff2") format("truetype");
  /* Thay đổi đường dẫn tương ứng */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AmericanTypewriter";
  src: url("/public/font/AmericanTypewriter.woff") format("truetype");
  /* Thay đổi đường dẫn tương ứng */
  font-weight: normal;
  font-style: normal;
}

/* ############################################################
############################################################ */
/* Phần contacts */
.contacts > h2 {
  font-size: clamp(30px, 13.5vw, 184px);
  transform: rotate(-5deg);
  font-family: "Timberline";
  letter-spacing: 1px;
  line-height: 0.9;
  text-align: left;
  width: 70vw;
  background-image: linear-gradient(60deg, rgb(255, 255, 247) 8.55094%, rgba(114, 114, 103, 0.048) 107.012%);
}

/*  */
.form-contacts input {
  border: none !important;
  border-bottom: 2px solid #283d4899 !important;
  background: none;
  font-size: 25px;
  overflow: visible;
  font-family: "AmericanTypewriter" ;
  padding: 8.5px 10px;
  width: 100%;
  outline: none;
}

::placeholder {
  text-align: center;
}
